import * as _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FONTS_NS, MENU_NS, PRICES_NS } from './Constants';
import { getFontTagCodes, getFontTagNames } from './Helpers';
import withMenu from './MenuHOC';
import ScrollableContainer from './ScrollableContainer';
import { AppStore, CategoryType, Store } from './types';

type Tags = AppStore['filters'][number]['options'];

const Prices: React.FC = () => {
    const { t, i18n } = useTranslation();
    const fonts = useSelector(({ app }: Store) =>
        _.sortBy(
            app.fonts?.filter(({ isPackage, isMainFont }) => isMainFont && !isPackage),
            ({ name }) => name
        )
    );
    const allTags = useSelector(({ app }: Store) =>
        app.filters
            ?.filter(({ type }) => type !== CategoryType.WEIGHTS)
            ?.reduce((acc, { options }) => acc.concat(options), [] as Tags)
    );

    const getTagAbbreviation = React.useCallback(
        ({ codeEn, codeGr }: Tags[number]) => {
            switch (i18n.language) {
                case 'en':
                    return codeEn;
                case 'el':
                default:
                    return codeGr;
            }
        },
        [i18n.language]
    );

    const getTagDescription = React.useCallback(
        ({ descriptionEn, descriptionGr }: Tags[number]) => {
            switch (i18n.language) {
                case 'en':
                    return descriptionEn;
                case 'el':
                default:
                    return descriptionGr;
            }
        },
        [i18n.language]
    );

    if (_.isEmpty(fonts) || _.isEmpty(allTags)) {
        return null;
    }

    return (
        <ScrollableContainer
            className='prices'
            header={t(`${MENU_NS}.prices`)}
            hasColumns={false}>
            <div>
                Οἱ γραμματοσειρὲς Ἀναγραφὴ πωλοῦνται καὶ διακινοῦνται ἀποκλειστικὰ ἀπὸ
                τὴν ἐταιρεία Personalized / Φανούριος Σισκάκης.
            </div>
            <div>
                Ἐκδίδεται Τιμολόγιο ἢ Ἀπόδειξη καὶ ἐπιβαρύνεται, κατὰ περίπτωση, μὲ ΦΠΑ
                Αὐστρίας (20%).
            </div>
            <br />
            <div className='title bold'>ΟΔΗΓΙΕΣ ΓΙΑ ΤΗΝ ΑΓΟΡΑ ΓΡΑΜΜΑΤΟΣΕΙΡΑΣ/ΩΝ</div>
            <div>
                Μπορεῖτε νά ἐπιλέξετε αὐτὸ ποὺ ἐπιθυμεῖτε, πατώντας στὸ σύμβολο τοῦ
                καλαθιοῦ, κάτω ἀριστερά στὴ σελὶδα τῶν λεπτομεριῶν κάθε γραμματοσειρᾶς.
            </div>
            <div>
                Μόλις ὁριστικοποιήσετε τὴν ἐπιλογή/ές σας, μπορεῖτε νὰ δεῖτε στὸ
                «Καλάθι» τὴ συνολικὴ εἰκόνα τῆς ἀγορᾶς σας, νὰ ἀφαιρέσετε καὶ/ἢ νὰ
                προχωρήσετε στὴν ὁλοκλήρωση της πληρωμῆς.
            </div>
            <div>
                Μετὰ τὴν ἐπιλογὴ τῶν γραμματοσειρῶν ποὺ ἐπιθυμείτε καὶ τὴν ἀποστολή τους
                στὸ καλάθι, πρέπει νὰ συμπληρώσετε τὰ στοιχεία ποὺ ἀπαιτοῦνται στὴ
                φόρμα.
            </div>
            <div>
                Γιὰ τὴν πώληση τῶν γραμματοσειρῶν ἐκδίδεται καὶ ἀποστέλεται, μέσω e-mail
                (invoicing@personalized.at), τιμολόγιο/ἀπόδειξη ἀπὸ τὴν ἐταιρεία
                Personalized.at (Αὐστρία). Παρακαλοῦμε, ἐλέγξτε τὰ spam mails σας, σὲ
                περίπτωση ποὺ τὸ μήνυμά μας βρίσκεται ἐκεῖ.
            </div>
            <div>
                Οἱ γραμματοσειρὲς σᾶς ἀποστέλονται, ἀμέσως μετά τὴν τραπεζικὴ κατάθεση
                καὶ τὴν ἀποστολὴ τοῦ σχετικοῦ ἀποδεικτικοῦ, στὸ e-mail ποὺ ἔχετε
                δηλώσει.
            </div>
            <br />
            <div>
                Ἂν γιὰ ὀποιοδήποτε λόγο ἀπαιτεῖται κάποια εἰδικὴ μεταχείριση, ἐκτὸς τῶν
                παρεχομένων ἐπιλογῶν, παρακαλοῦμε ἐπικοινωνεῖστε μαζί μας.
            </div>
            <br />
            <div className='title bold'>{t(`${PRICES_NS}.abbreviations`)}</div>
            <div className='abbreviations'>
                {allTags
                    ?.filter(
                        ({ fontTagCategoryID }) =>
                            fontTagCategoryID === CategoryType.LANGUAGES
                    )
                    .map(tag => (
                        <div className='language-description' key={tag.id}>
                            <div className='abbreviation'>
                                {getTagAbbreviation(tag)}:
                            </div>
                            <div>{getTagDescription(tag)}</div>
                        </div>
                    ))}
            </div>
            <div className='font-prices'>
                {fonts
                    .sort((a, b) =>
                        a.code.toLowerCase().localeCompare(b.code.toLowerCase())
                    )
                    .map(
                        (
                            {
                                id,
                                fullPageName,
                                supportedLanguageIDs,
                                weightIDs,
                                price,
                                fontCategoryIDs
                            },
                            i
                        ) => (
                            <div key={id} className='font-price'>
                                {i + 1}. <span className='title'>{fullPageName}</span> -{' '}
                                {getFontTagNames(fontCategoryIDs, allTags).join(' - ')}{' '}
                                -{' '}
                                {getFontTagCodes(supportedLanguageIDs, allTags).join(
                                    '/'
                                )}
                                {weightIDs.length
                                    ? ` - ${weightIDs.length} ${t(
                                          `${FONTS_NS}.weight`
                                      )}`
                                    : ''}{' '}
                                -{' '}
                                <span className='title'>
                                    {t(`${FONTS_NS}.euro`)} {price}
                                </span>
                            </div>
                        )
                    )}
            </div>
        </ScrollableContainer>
    );
};

export default withMenu(Prices);
