import { ThunkAction as ThunkActionCreator } from 'redux-thunk';
import { AnyAction } from 'redux';
import { SnackbarKey } from 'notistack';
import { Store, AppStore } from '../types';

export enum ActionType {
    REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION',
    ENQUEUE_NOTIFICATION = 'ENQUEUE_NOTIFICATION',
    SET_FONTS = 'SET_FONTS',
    SET_FILTERS = 'SET_FILTERS',
    UPDATE_FILTER_OPTION = 'UPDATE_FILTER_OPTION',
    UPDATE_CART = 'UPDATE_CART'
}

interface UpdateCart {
    type: ActionType.UPDATE_CART;
    payload: AppStore['cart'];
}

interface CloseNotification {
    type: ActionType.REMOVE_NOTIFICATION;
    payload: SnackbarKey;
}

interface EnqueueNotification {
    type: ActionType.ENQUEUE_NOTIFICATION;
    payload: AppStore['notifications'][number];
}

interface SetFonts {
    type: ActionType.SET_FONTS;
    payload: AppStore['fonts'];
}

interface SetFilters {
    type: ActionType.SET_FILTERS;
    payload: AppStore['filters'];
}

export interface UpdateFilterOption {
    type: ActionType.UPDATE_FILTER_OPTION;
    payload: {
        id: number;
        categoryId: number;
    };
}

export type Action =
    | CloseNotification
    | EnqueueNotification
    | SetFonts
    | SetFilters
    | UpdateFilterOption
    | UpdateCart;

export type ThunkAction<R = void> = ThunkActionCreator<R, Store, unknown, AnyAction>;
