import React from 'react';
import { range } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ABOUT_NS, MENU_NS } from './Constants';
import withMenu from './MenuHOC';
import ScrollableContainer from './ScrollableContainer';

const LEFT_COL_TEXT_NO = 2;
const RIGHT_COL_TEXT_NO = 3;

const About: React.FC = () => {
    const { t } = useTranslation();

    return (
        <ScrollableContainer className='about' header={t(`${MENU_NS}.about`)}>
            <div className='detail-columns'>
                <div className='left no-border'>
                    {range(1, LEFT_COL_TEXT_NO + 1).map(num => (
                        <div key={num} className='about-text'>
                            {t(`${ABOUT_NS}.left_${num}`)}
                        </div>
                    ))}
                </div>
                <div className='right no-border'>
                    {range(1, RIGHT_COL_TEXT_NO + 1).map(num => (
                        <div key={num} className='about-text'>
                            {t(`${ABOUT_NS}.right_${num}`)}
                        </div>
                    ))}
                </div>
            </div>
        </ScrollableContainer>
    );
};

export default withMenu(About);
