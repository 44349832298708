import { FormApi, ValidationErrors } from 'final-form';
import * as React from 'react';
import * as _ from 'lodash';
import { Field, Form, FormRenderProps } from 'react-final-form';
import FontAwesome from 'react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { addRemoveToCart, enqueueNotification, updateCart } from './actions/Actions';
import { BASE_URL, CART_NS } from './Constants';
import { getCartTotal } from './Helpers';
import withMenu from './MenuHOC';
import ScrollableContainer from './ScrollableContainer';
import { Font, Store } from './types';

enum PurchaseType {
    PRIVATE = 'private',
    COMPANY = 'company'
}

type FormValues = {
    fullName: string;
    email: string;
    purchaseType: PurchaseType;
    companyInfo?: string;
    comments?: string;
    address?: string;
};

const INITIAL_VALUES: Partial<FormValues> = { purchaseType: PurchaseType.PRIVATE };

const Condition = <T extends keyof FormValues>({
    when,
    is,
    children
}: {
    when: T;
    is: FormValues[T];
    children: JSX.Element;
}) => (
    <Field name={when} subscription={{ value: true }}>
        {({ input: { value } }) => (value === is ? children : null)}
    </Field>
);

const Cart: React.FC = () => {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = React.useState(false);

    const dispatch = useDispatch();
    const cart = useSelector(({ app }: Store) => app.cart);

    const onRemoveFromCart = React.useCallback(
        (font: Font) => () => {
            dispatch(addRemoveToCart(font));
        },
        [dispatch]
    );

    const renderCart = React.useCallback(
        (values: FormValues) => {
            if (!cart.length) {
                return (
                    <tr>
                        <td colSpan={3} className='empty'>
                            {t(`${CART_NS}.table.empty`)}
                        </td>
                    </tr>
                );
            }

            const total = getCartTotal(cart);

            return (
                <>
                    {cart.map(font => (
                        <tr key={font.id} className='font-item'>
                            <td>{font.fullPageName}</td>
                            <td>{font.price}</td>
                            <td>
                                <FontAwesome
                                    className='remove'
                                    name='times-circle'
                                    title={t(`${CART_NS}.table.remove`)}
                                    onClick={onRemoveFromCart(font)}
                                />
                            </td>
                        </tr>
                    ))}
                    <tr className='cart-total'>
                        <td>{t(`${CART_NS}.form.total.${values.purchaseType}`)}</td>
                        <td>
                            {values.purchaseType === PurchaseType.COMPANY
                                ? total
                                : total + total * 0.2}
                        </td>
                    </tr>
                </>
            );
        },
        [cart, onRemoveFromCart, t]
    );

    const getInvoiceText = React.useCallback(() => {
        if (i18n.language === 'el') {
            return (
                <div>
                    Γιὰ τὴν πώληση τῶν γραμματοσειρῶν ἐκδίδεται καὶ ἀποστέλεται, μέσω
                    e-mail (invoicing@personalized.at), τιμολόγιο/ἀπόδειξη ἀπὸ τὴν
                    ἐταιρεία Personalized.at (Αὐστρία).
                    <div>
                        Παρακαλοῦμε, ἐλέγξτε τὰ spam mails σας, σὲ περίπτωση ποὺ τὸ
                        μήνυμά μας βρίσκεται ἐκεῖ.
                    </div>
                    <div>
                        Μετὰ τὴν ἀποστολὴ τῆς Αἴτησης Παραγγελίας, θὰ λάβετε e-mail, στὸ
                        ὁποῖο πρέπει νὰ ἐπιβεβαιώσετε τὴν ὁρθότητα τῶν στοιχείων σας.
                    </div>
                </div>
            );
        }

        return (
            <div>
                For the sale of the fonts, an invoice/receipt from the company
                Personalized.at (Austria) is issued and sent via e-mail
                (invoicing@personalized.at).
                <div>Please check your spam mails in case our message is there.</div>
                <div>
                    After the Payment Request you will receive an email to confirm your
                    input data.
                </div>
            </div>
        );
    }, [i18n.language]);

    const renderPaymentForm = React.useCallback(
        ({
            handleSubmit,
            hasValidationErrors,
            values
        }: FormRenderProps<FormValues>) => (
            <>
                <table className='cart-table'>
                    <thead>
                        <tr>
                            <td className='header font'>
                                {t(`${CART_NS}.table.font`)}
                            </td>
                            <td className='header'>{t(`${CART_NS}.table.price`)} €</td>
                            <td />
                        </tr>
                    </thead>
                    <tbody>{renderCart(values)}</tbody>
                </table>
                <div className='table-title'>Στοιχεία ἀποστολῆς/τιμολόγησης</div>
                <form onSubmit={handleSubmit} className='form'>
                    <table>
                        <tbody>
                            <tr>
                                <td className='purchase-type'>
                                    <div className='radio'>
                                        <label
                                            className='custom-control-label'
                                            htmlFor={PurchaseType.PRIVATE}>
                                            {t(`${CART_NS}.form.type_private`)}
                                        </label>
                                        <Field
                                            name='purchaseType'
                                            component='input'
                                            type='radio'
                                            value={PurchaseType.PRIVATE}
                                            id={PurchaseType.PRIVATE}
                                        />
                                    </div>
                                    <div className='radio'>
                                        <label
                                            className='custom-control-label'
                                            htmlFor={PurchaseType.COMPANY}>
                                            {t(`${CART_NS}.form.type_company`)}
                                        </label>
                                        <Field
                                            name='purchaseType'
                                            component='input'
                                            type='radio'
                                            value={PurchaseType.COMPANY}
                                            id={PurchaseType.COMPANY}
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='form-label'>
                                    {t(`${CART_NS}.form.name`)}
                                    <span className='mandatory'>*</span>:
                                </td>
                                <td className='cell'>
                                    <Field name='fullName'>
                                        {({ input }) => (
                                            <input
                                                {...input}
                                                type='text'
                                                placeholder={t(`${CART_NS}.form.name`)}
                                            />
                                        )}
                                    </Field>
                                </td>
                            </tr>
                            <Condition when='purchaseType' is={PurchaseType.COMPANY}>
                                <tr>
                                    <td className='form-label'>
                                        {t(`${CART_NS}.form.company_info`)}
                                        <span className='mandatory'>*</span>:
                                    </td>
                                    <td className='cell'>
                                        <Field
                                            name='companyInfo'
                                            component='textarea'
                                            placeholder={t(
                                                `${CART_NS}.form.company_info`
                                            )}
                                            rows={4}
                                        />
                                    </td>
                                </tr>
                            </Condition>
                            <tr>
                                <td className='form-label'>
                                    {t(`${CART_NS}.form.address`)}
                                    <span className='mandatory'>*</span>:
                                </td>
                                <td className='cell'>
                                    <Field
                                        name='address'
                                        component='textarea'
                                        placeholder={t(`${CART_NS}.form.address`)}
                                        rows={2}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className='form-label'>
                                    {t(`${CART_NS}.form.email`)}
                                    <span className='mandatory'>*</span>:
                                </td>
                                <td className='cell'>
                                    <Field name='email'>
                                        {({ input }) => (
                                            <input
                                                {...input}
                                                type='email'
                                                placeholder={t(`${CART_NS}.form.email`)}
                                            />
                                        )}
                                    </Field>
                                </td>
                            </tr>
                            <tr>
                                <td className='form-label' />
                                <td className='cell'>
                                    <Field
                                        name='comments'
                                        component='textarea'
                                        placeholder={t(`${CART_NS}.form.comments`)}
                                        rows={6}
                                    />
                                </td>
                                <td className='submit-wrapper'>
                                    <button
                                        type='submit'
                                        disabled={
                                            hasValidationErrors ||
                                            loading ||
                                            !cart.length
                                        }>
                                        {t(`${CART_NS}.form.pay`)}
                                    </button>
                                </td>
                                <td style={{ verticalAlign: 'bottom' }}>
                                    {loading && (
                                        <FontAwesome
                                            className='spinner'
                                            name='spinner fa-pulse'
                                            style={{ marginBottom: '8px' }}
                                        />
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={3} className='mandatory'>
                                    * {t(`${CART_NS}.form.mandatory`)}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={3}>{getInvoiceText()}</td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </>
        ),
        [cart.length, getInvoiceText, loading, renderCart, t]
    );

    const validateForm = React.useCallback((values: FormValues) => {
        const errors: ValidationErrors = {};

        if (!values.fullName) {
            errors.fullName = 'Required';
        }
        if (!values.email) {
            errors.email = 'Required';
        }
        if (!values.purchaseType) {
            errors.purchaseType = 'Required';
        }
        if (values.purchaseType === PurchaseType.COMPANY && !values.companyInfo) {
            errors.companyInfo = 'Required';
        }
        if (!values.address) {
            errors.address = 'Required';
        }

        return errors;
    }, []);

    const handlePaymentRequest = React.useCallback(
        (values: FormValues, form: FormApi<FormValues, Partial<FormValues>>) => {
            setLoading(true);

            const payload = {
                ...values,
                isCompany: values.purchaseType === PurchaseType.COMPANY,
                fontIDs: cart.map(({ id }) => id),
                contactLanguage: i18n.language
            };

            fetch(`${BASE_URL}/ShowInterestInBuyingFonts`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(_.omit(payload, 'purchaseType'))
            })
                .then(res => res.text())
                .then(() => {
                    form.reset({ ...INITIAL_VALUES });
                    dispatch(updateCart([]));

                    dispatch(
                        enqueueNotification({
                            key: new Date().getTime(),
                            message: t(`${CART_NS}.form.success`),
                            options: { variant: 'success' }
                        })
                    );
                })
                .catch(e => {
                    dispatch(
                        enqueueNotification({
                            key: new Date().getTime(),
                            message: e,
                            options: { variant: 'error' }
                        })
                    );
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [cart, dispatch, i18n.language, t]
    );

    return (
        <ScrollableContainer
            hasColumns={false}
            header={t(`${CART_NS}.title`)}
            className='user-cart'>
            <div>
                <Form<FormValues>
                    render={renderPaymentForm}
                    onSubmit={handlePaymentRequest}
                    initialValues={{ ...INITIAL_VALUES }}
                    validate={validateForm}
                />
            </div>
        </ScrollableContainer>
    );
};

export default withMenu(Cart);
