import React from 'react';
import { useTranslation } from 'react-i18next';
import { MENU_NS } from './Constants';
import LinkTo from './LinkTo';
import withMenu from './MenuHOC';
import ScrollableContainer from './ScrollableContainer';

const Contact: React.FC = () => {
    const { t, i18n } = useTranslation();

    const getBody = React.useCallback(() => {
        switch (i18n.language) {
            case 'en':
                return (
                    <>
                        <div>
                            <br />
                            <div>
                                Distribution: Fanourios Siskakis /{' '}
                                <LinkTo
                                    href='https://personalized.at/'
                                    content='personalized.at'
                                />
                            </div>
                            <div>
                                E-mail:{' '}
                                <LinkTo
                                    href='mailto:info@anagrafi.com'
                                    content='info@anagrafi.com'
                                />
                                ,{' '}
                                <LinkTo
                                    href='mailto:info@personalized.at'
                                    content='info@personalized.at'
                                />
                            </div>
                            <div>
                                Web site:{' '}
                                <LinkTo
                                    href='http://www.anagrafi.com/'
                                    content='http://www.anagrafi.com/'
                                />
                            </div>
                            <br />
                            <div>Design and research: Konstantinos F. Siskakis</div>
                            <div>
                                Web site:{' '}
                                <LinkTo
                                    href='http://www.grafist.eu'
                                    content='http://www.grafist.eu'
                                />
                                /
                            </div>
                            <div>Mob.: +30 697 7977052</div>
                            <br />
                            <br />
                            <div>
                                After choosing the fonts you want and sending them to
                                the cart, you must fill in the information required in
                                the form (Name, email, your property, and comments if
                                any).
                            </div>
                            <div>
                                For the sale of the fonts, an invoice/receipt from
                                Personalized.at (Austria) is issued and sent via e-mail
                                (invoicing@personalized.at).
                            </div>
                            <div>
                                Please check your spam mails, in case our message is
                                there.
                            </div>
                            <div>
                                The fonts are sent to you to the e-mail you have
                                declared, immediately after the bank deposit and the
                                sending of the relevant receipt.
                            </div>
                        </div>
                    </>
                );
            case 'el':
            default:
                return (
                    <>
                        <div>
                            <br />
                            <div>
                                Διάθεση: Φανούριος Σισκάκης /{' '}
                                <LinkTo
                                    href='https://personalized.at/'
                                    content='personalized.at'
                                />
                            </div>
                            <div>
                                E-mail:{' '}
                                <LinkTo
                                    href='mailto:info@anagrafi.com'
                                    content='info@anagrafi.com'
                                />
                                ,{' '}
                                <LinkTo
                                    href='mailto:info@personalized.at'
                                    content='info@personalized.at'
                                />
                            </div>
                            <div>
                                Web site:{' '}
                                <LinkTo
                                    href='http://www.anagrafi.com/'
                                    content='http://www.anagrafi.com/'
                                />
                            </div>
                            <br />
                            <div>Σχεδίαση: Kωνσταντῖνος Φ. Σισκάκης</div>
                            <div>
                                Web site:{' '}
                                <LinkTo
                                    href='http://www.grafist.eu'
                                    content='http://www.grafist.eu'
                                />
                                /
                            </div>
                            <div>Κιν. τηλ.: +30 697 7977052</div>
                            <br />
                            <br />
                            <div>
                                Μετὰ τὴν ἐπιλογὴ τῶν γραμματοσειρῶν ποὺ ἐπιθυμείτε καὶ
                                τὴν ἀποστολή τους στὸ καλάθι, πρέπει νὰ συμπληρώσετε τὰ
                                στοιχεία ποὺ ἀπαιτοῦνται στὴ φόρμα (Ὀνοματεπώνυμο,
                                email, τὴν ἰδιότητά σας, καὶ σχόλια ἂν ὑπάρχουν).
                            </div>
                            <div>
                                Γιὰ τὴν πώληση τῶν γραμματοσειρῶν ἐκδίδεται καὶ
                                ἀποστέλεται, μέσω e-mail (invoicing@personalized.at),
                                τιμολόγιο/ἀπόδειξη ἀπὸ τὴν ἐταιρεία Personalized.at
                                (Αὐστρία).
                            </div>
                            <div>
                                Παρακαλοῦμε, ἐλέγξτε τὰ spam mails σας, σὲ περίπτωση ποὺ
                                τὸ μήνυμά μας βρίσκεται ἐκεῖ.
                            </div>
                            <div>
                                Οἱ γραμματοσειρὲς σᾶς ἀποστέλονται, ἀμέσως μετά τὴν
                                τραπεζικὴ κατάθεση καὶ τὴν ἀποστολὴ τοῦ σχετικοῦ
                                ἀποδεικτικοῦ, στὸ e-mail ποὺ ἔχετε δηλώσει.
                            </div>
                        </div>
                    </>
                );
        }
    }, [i18n.language]);

    return (
        <ScrollableContainer
            className='contact'
            header={t(`${MENU_NS}.contact`)}
            hasColumns={false}>
            {getBody()}
        </ScrollableContainer>
    );
};

export default withMenu(Contact);
