import React from 'react';
import { useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { AMAZON_BASE_URL, FONTS_NS } from './Constants';
import withMenu from './MenuHOC';
import { AppStore, CategoryType, Store } from './types';
import { getFontTagCodes } from './Helpers';
import Filter from './Filter';

const Fonts: React.FC<{}> = () => {
    const { t } = useTranslation();
    const filters = useSelector(({ app }: Store) =>
        (app.filters || []).filter(({ type }) => type !== CategoryType.WEIGHTS)
    );
    const allTags = filters.reduce(
        (acc, { options }) => acc.concat(options),
        [] as AppStore['filters'][number]['options']
    );
    const fonts = useSelector(({ app }: Store) =>
        sortBy(
            app.fonts?.filter(({ isPackage, isMainFont }) => isMainFont && !isPackage),
            ({ name }) => name
        )
    );

    const getPromoImage = React.useCallback(
        (code: string, hasPromoImgConfigured: boolean): string =>
            hasPromoImgConfigured
                ? `${AMAZON_BASE_URL}/fonts/${code}/promo.png`
                : `${AMAZON_BASE_URL}/general/not_yet.png`,
        []
    );

    const filteredFonts = filters.reduce((acc, { options }) => {
        const selectedOptions = options.filter(({ checked }) => checked);

        return acc.filter(({ fontTagIDs }) =>
            selectedOptions.some(({ id }) => fontTagIDs.includes(id))
        );
    }, fonts);

    return (
        <div className='fonts-wrapper'>
            <div className='filters'>
                {filters.map(f => (
                    <Filter key={f.id} category={f} />
                ))}
            </div>
            <div className='fonts'>
                {filteredFonts.map(
                    ({
                        id,
                        name,
                        fullPageName,
                        isNewFont,
                        code,
                        hasPromoImgConfigured,
                        hasNewVersion,
                        supportedLanguageIDs,
                        weightIDs
                    }) => (
                        <Link
                            to={`/fonts/${fullPageName}`}
                            key={id}
                            className='font-wrapper'>
                            <div className='top'>
                                <div className='font-name'>
                                    <div
                                        className={clsx('name', {
                                            small: name.length > 14
                                        })}>
                                        {name}
                                    </div>
                                    {isNewFont && (
                                        <div className='new-font'>
                                            {t(`${FONTS_NS}.new_font`)}
                                        </div>
                                    )}
                                    {hasNewVersion && (
                                        <div className='updated-font'>UPD</div>
                                    )}
                                </div>
                                <div className='font-details'>
                                    <div className='weight'>
                                        {weightIDs.length} {t(`${FONTS_NS}.weight`)}
                                    </div>
                                    <div className='language'>
                                        {getFontTagCodes(
                                            supportedLanguageIDs,
                                            allTags
                                        ).join('/')}
                                    </div>
                                </div>
                            </div>
                            <div className='strip' />
                            <div>
                                <img src={getPromoImage(code, hasPromoImgConfigured)} />
                            </div>
                        </Link>
                    )
                )}
            </div>
        </div>
    );
};

export default withRouter(withMenu(Fonts));
