import React from 'react';
import { useTranslation } from 'react-i18next';
import { MENU_NS } from './Constants';
import withMenu from './MenuHOC';
import ScrollableContainer from './ScrollableContainer';

const CustomServices: React.FC = () => {
    const { t, i18n } = useTranslation();

    const getBody = React.useCallback(() => {
        switch (i18n.language) {
            case 'en':
            case 'el':
            default:
                return (
                    <>
                        <div>
                            <br />
                            <div className='bold'>
                                Σχεδίαση γραμματοσειρῶν ἀποκλειστικῆς χρήσης
                            </div>
                            <div>
                                Γιὰ μεγαλύτερη εὐελιξία ἀλλὰ καὶ ἰδιαιτερότητα στὴ χρήση
                                τοῦ κειμένου ὑπάρχει ἡ δυνατότητα δημιουργίας
                                γραμματοσειρῶν γιὰ ἐταιρικὴ ἢ προσωπικὴ χρήση μὲ
                                ἀποκλειστικὰ δικαιώματα. Μπορεῖ, ἐπίσης, νὰ γίνει
                                μετατροπὴ σὲ ἤδη ὑπάρχουσες οἰκογένειες γιὰ μεγαλύτερη
                                οἰκονομία καὶ ταχύτητα. Τὰ δικαιώματα χρήσης (χρήση /
                                μεταβίβαση / πώληση) στὶς γραμματοσειρὲς αὐτὲς ἀνήκουν
                                ἀποκλειστικὰ στὸν πελάτη. Ὁ χρόνος ποὺ χρειάζεται γιὰ
                                μιὰ τέτοια ἐργασία ποικίλει ἀπὸ ἕναν ἔως μερικοὺς μῆνες.
                            </div>
                            <br />
                        </div>
                        <div>
                            <div className='bold'>
                                Σχεδίαση γραμματοσειρῶν μὲ βάση παλαιὰ χειρόγραφα ἢ
                                ἰδιόγραφα κείμενα
                            </div>
                            <div>
                                Δὲν εἶναι λίγες οἱ φορές, ποὺ μελετώντας παλιὰ
                                χειρόγραφα, μᾶς ἐνθουσιάζουν καὶ μᾶς ἐμπνέουν τόσο πολὺ
                                ποὺ θὰ θέλαμε νὰ δημιουργήσουμε ἕνα πολὺ προσωπικὸ ὕφος
                                γραφῆς βασισμένο σὲ αὐτά. Ἡ διαδικασία εἶναι ἁπλὴ ἀλλά,
                                βέβαια, χρονοβόρα. Δημιουργοῦμε καινούργιους χαρακτῆρες
                                μὲ βάση τὰ ὑπάρχοντα γράμματα, πρῶτα στὸ χαρτὶ καὶ μετά,
                                μὲ τὴ βοήθεια scanner, στὴν ὀθόνη τοῦ ὑπολογιστῆ.
                                Ἀποκαθιστοῦμε τυχὸν ἀτέλειες ἢ καὶ ἐλλείψεις
                                συγκεκριμένων γραμμάτων ποὺ ἔτυχε νὰ μὴν ἔχουν
                                καταγραφεῖ στὸ χειρόγραφο (συνήθως κεφαλαῖα). Ἂν εἶναι
                                ἐπιθυμητό, ἀκολουθοῦμε τοὺς σύγχρονους κανόνες σχεδίασης
                                χαρακτήρων, χρησιμοποιοῦμε δηλαδὴ κοινὸ ὕψος καὶ γραμμὴ
                                βάσης, ἐκτὸς ἂν ὑπάρχει λόγος γιὰ τὸ ἀντίθετο. Ἔτσι,
                                δημιουργοῦμε μὲν γραμματοσειρὲς ποὺ εἶναι βασισμένες ἢ
                                ἐμπνευσμένες ἀπὸ παλιὰ χειρόγραφα, ἀκολουθοῦμε ὅμως τοὺς
                                σύγχρονους κανόνες αἰσθητικῆς τοῦ κειμένου. Ἡ τεχνολογία
                                OpenType μᾶς δίνει πλέον τὴ δυνατότητα γιὰ συμπλέγματα
                                χαρακτήρων, ποικιλίες σχεδίασης συγκεκριμένων χαρακτήρων
                                καὶ ἄλλα πολλὰ χαρακτηριστικὰ τὰ ὁποῖα προσδίδουν στὸ
                                κείμενο μιὰ ἐξαιρετικὰ ἰδιαίτερη εἰκόνα, κατὰ τὸ δοκοῦν.
                            </div>
                            <br />
                        </div>
                        <div>
                            <div className='bold'>
                                Διορθώσεις προβλημάτων γραμματοσειρῶν
                            </div>
                            <div>
                                Ὑπάρχει ἡ δυνατότητα ἀποκατάστασης προβλημάτων ποὺ
                                μπορεῖ νὰ παρουσιαστοῦν στὴ χρήση μιᾶς γραμματοσειρᾶς,
                                ὅπως:
                                <div>
                                    - Ἀπουσία συγκεκριμένων χαρακτήρων (ἀρχαϊκῶν
                                    συμβόλων, τονούμενων λατινικῶν (λατινογενῶν
                                    ἀλφαβήτων), νομισματικῶν συμβόλων, κλασμάτων κ.λπ.),
                                    τύπων τονισμοῦ κ.λπ.
                                </div>
                                <div>
                                    - Δυσλειτουργικὲς ἀποστάσεις μεταξὺ τῶν χαρακτήρων
                                    (kerning) ἢ τῶν γραμμῶν τοῦ κειμένου
                                    (διάστιχο/leading) μιᾶς γραμματοσειρᾶς
                                </div>
                                <div>- Κακὴ σχεδίαση συγκεκριμένων χαρακτήρων</div>
                                <div>
                                    - Διορθώσεις στὴ σχεδίαση τῶν ἀριθμητικῶν χαρακτήρων
                                </div>
                                <div>
                                    - Σχεδιασμὸς ἀριθμῶν μεταβλητῆς βάσης καὶ ὕψους
                                    (παλαιοῦ τύπου/oldstyle numerals)
                                </div>
                                <div>
                                    - Προσθήκη χαρακτήρων, συμβόλων, διακοσμητικῶν
                                    (ἀκόμα καὶ λογοτύπων!)
                                </div>
                            </div>
                            <br />
                        </div>
                        <div>
                            <div className='bold'>
                                Ἐξελληνισμὸς ἢ ἐκλατινισμὸς γραμματοσειρῶν
                            </div>
                            <div>
                                Δὲν εἶναι λίγες οἱ οἰκογένειες γραμματοσειρῶν, κυρίως
                                αὐτὲς ποὺ προέρχονται ἀπὸ προηγούμενα χρόνια, στὶς
                                ὁποῖες ἐπιβάλλεται νὰ σχεδιαστοῦν ἑλληνικοὶ ἢ λατινικοὶ
                                χαρακτῆρες, ἀντίστοιχα. Μὲ βάση τὸ ὕφος ποὺ ἐπιθυμεῖ
                                κανείς, μπορεῖ νὰ ἀποκτήσει μιὰ οἰκογένεια ἡ ὁποῖα θὰ
                                περιέχει πλέον ὑποστήριξη τοῦ ἑλληνικοῦ ἢ τοῦ λατινικοῦ
                                ἀλφαβήτου. Φυσικά, εἶναι δυνατὸν νὰ σχεδιαστοῦν ὅλα τὰ
                                γνωστὰ λατινικὰ ἀλφάβητα, καθὼς καὶ τὰ κυριλλικά.
                            </div>
                            <br />
                        </div>
                        <div>
                            <div className='bold'>
                                Μετατροπὴ γραμματοσειρῶν σὲ πολυτονικές
                            </div>
                            <div>
                                Ἡ μετατροπὴ μιᾶς γραμματοσειρᾶς σὲ πολυτονικὴ εἶναι μιὰ
                                διαδικασία ἡ ὁποία δὲν ἦταν αὐτονόητη παλαιότερα λόγῳ
                                τῶν μεγάλων τεχνικῶν περιορισμῶν ποὺ ὑπῆρχαν πρὶν τὴν
                                καθιέρωση τοῦ τύπου Unicode. Ἔτσι, ὑπάρχουν δεκάδες
                                γραμματοσειρὲς οἱ ὁποῖες εἶναι πλέον τεχνικὰ δυνατὸν νὰ
                                μετατραποῦν σὲ πολυτονικές.
                            </div>
                            <br />
                        </div>
                        <div>
                            <div className='bold'>
                                Κατασκευὴ συμπλεγμάτων (ligatures) καὶ ἄλλων
                                χαρακτηριστικῶν σὲ ἤδη ὑπάρχουσες γραμματοσειρές
                            </div>
                            <div>
                                Ἡ ὕπαρξη συμπλεγμάτων χαρίζει στὸ κείμενο μιὰ εὐχάριστη
                                καὶ αλαίσθητη εἰκόνα, ἕνα ἄρωμα καλλιγραφίας, ὅπου τὰ
                                γράμματα συμπλέκονται ὄμορφα καὶ ἔξυπνα μεταξύ τους.
                                Ἔτσι, τὸ κείμενό μας μπορεῖ νὰ ἀποκτήσει μιὰ αἴσθηση
                                χειρόγραφης ἢ καλλιγραφικῆς εἰκόνας, ξεφεύγοντας ἀπὸ τὴν
                                τυπικὴ διάταξη τῶν χαρακτήρων. Προϋπόθεση γιὰ τὴ
                                δημιουργία συμπλεγμάτων εἶναι νὰ ὑποστηρίζονται τὰ
                                χαρακτηριστικὰ OpenType ἀπὸ τὸ πρόγραμμα τὸ ὁποῖο
                                χρησιμοποιοῦμε. Προγράμματα ὅπως τὸ Adobe InDesign,
                                Adobe Illustrator, QuarkXPress, CorelDraw κ.ἄ.
                                ὑποστηρίζουν (συνολικὰ ἢ μερικά) τὴ δυνατότητα αὐτή.
                                Ἐκτὸς ἀπὸ τὴ σύμπλεξη χαρακτήρων μεταξύ τους, μποροῦμε,
                                ἐκμεταλλευόμενοι τὴν τεχνολογία OpenType, νὰ παρέμβουμε
                                στὸ κείμενο, μὲ τρόπους ποὺ χαρίζουν μιὰ ἀπαράμιλλη
                                αἰσθητικὴ καὶ ἰδιαιτερότητα ὅπως:
                                <div>
                                    - Εἰδικὰ συμπλέγματα (discretionary ligatures)
                                </div>
                                <div>- Κλάσματα ἀριθμῶν (fractions)</div>
                                <div>
                                    - Ἀριθμοὺς μεταβλητῆς βάσης καὶ ὕψους (old style
                                    numerals)
                                </div>
                                <div>
                                    - Εἰδικὰ σχεδιασμένους ἀρχικοὺς χαρακτῆρες μιᾶς
                                    πρότασης ἢ τελικοὺς μιᾶς λέξης (swash)
                                </div>
                                <div>
                                    - Ἐναλλακτικὰ στυλιστικὰ σύνολα χαρακτήρων
                                    (stylistic sets)
                                </div>
                            </div>
                            <br />
                        </div>
                        <div>
                            <div className='bold'>Γραμματοσειρὲς ἀρχιγραμμάτων</div>
                            <div>
                                Μποροῦν νὰ σχεδιαστοῦν καὶ νὰ δημιουργηθοῦν εἰδικὲς
                                γραμματοσειρὲς μὲ διακοσμητικὰ ἀρχιγράμματα ἀπὸ παλιὰ
                                χειρόγραφα, ἰδιόγραφα ἢ βιβλία, γιὰ χρήση σὲ
                                ἐκκλησιαστικὰ ἢ μὴ κείμενα.
                            </div>
                            <br />
                        </div>
                    </>
                );
        }
    }, [i18n.language]);

    return (
        <ScrollableContainer
            className='custom-services'
            header={t(`${MENU_NS}.custom-services`)}
            hasColumns={false}>
            {getBody()}
        </ScrollableContainer>
    );
};

export default withMenu(CustomServices);
