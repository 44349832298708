import clsx from 'clsx';

const ScrollableContainer: React.FC<{
    className?: string;
    headerClassName?: string;
    header: JSX.Element;
    hasColumns?: boolean;
}> = ({ children, className, header, hasColumns = true, headerClassName }) => (
    <div className={clsx('scrollable-container', className)}>
        <div className='wrapper'>
            <div className='top'>
                <div className={clsx('menu-header', headerClassName)}>{header}</div>
                {hasColumns ? (
                    <div className='border'>
                        <div className='left' />
                        <div className='right' />
                    </div>
                ) : (
                    <div className='single-border' />
                )}
            </div>
            <div className='bottom'>{children}</div>
        </div>
    </div>
);

export default ScrollableContainer;
