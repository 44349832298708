import clsx from 'clsx';

const LinkTo: React.FC<{
    href: string;
    content?: string | JSX.Element;
    className?: string;
}> = ({ href, content, className }) => (
    <a href={href} className={clsx('link', className)} target='_blank' rel='noreferrer'>
        {content || href}
    </a>
);

export default LinkTo;
