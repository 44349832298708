import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import { AMAZON_BASE_URL, FONTS_NS } from './Constants';
import withMenu from './MenuHOC';
import { AppStore, Store } from './types';
import { getFontTagNames, getLocalizationConfig, isFontInCart } from './Helpers';
import ScrollableContainer from './ScrollableContainer';
import { addRemoveToCart } from './actions/Actions';

const FontDetails: React.FC<RouteComponentProps<{ font: string }>> = ({ match }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const fonts = useSelector(({ app }: Store) => app.fonts);
    const cart = useSelector(({ app }: Store) => app.cart);
    const allTags = useSelector(({ app }: Store) =>
        (app.filters || [])?.reduce(
            (acc, { options }) => acc.concat(options),
            [] as AppStore['filters'][number]['options']
        )
    );

    const font = fonts?.find(({ fullPageName }) => fullPageName === match.params.font);

    const getFontPrice = React.useCallback(() => {
        if (_.isNil(font.priceActionPercentage)) {
            return font.price;
        }

        return (
            <>
                <span className='original-price'>({font.price})</span>{' '}
                {font.finalPriceAfterAction}{' '}
                <span className='small'>({font.priceActionPercentage}% Discount)</span>
            </>
        );
    }, [font?.finalPriceAfterAction, font?.price, font?.priceActionPercentage]);

    const getColImages = React.useCallback(
        (side: 'left' | 'right') => {
            const colCounter =
                side === 'left'
                    ? font.maxLeftColumnCounter
                    : font.maxRightColumnCounter;

            return (
                !!colCounter &&
                _.range(1, colCounter + 1).map(counter => (
                    <div className='image-wrapper' key={counter}>
                        <img
                            src={`${AMAZON_BASE_URL}/fonts/${font.code}/${side}Column_${counter}.png`}
                        />
                    </div>
                ))
            );
        },
        [font?.code, font?.maxLeftColumnCounter, font?.maxRightColumnCounter]
    );

    const getLogoImage = React.useCallback(
        (code: string, hasLogoImgConfigured: boolean): string =>
            hasLogoImgConfigured
                ? `${AMAZON_BASE_URL}/fonts/${code}/logo.png`
                : `${AMAZON_BASE_URL}/general/not_yet.png`,
        []
    );

    const handleAddToCart = React.useCallback(() => {
        if (!isFontInCart(cart, font.id)) {
            dispatch(addRemoveToCart(font));
        }
    }, [dispatch, cart, font]);

    if (_.isEmpty(fonts) || _.isEmpty(allTags)) {
        return null;
    }

    return (
        <ScrollableContainer
            className='font font-details'
            headerClassName='font-name'
            header={
                <>
                    {font.name}
                    {font.isNewFont && (
                        <span className='new-font'>{t(`${FONTS_NS}.new_font`)}</span>
                    )}
                    {font.hasNewVersion && <span className='updated-font'>UPD</span>}
                </>
            }>
            <div className='detail-columns'>
                <div className='left'>
                    <div
                        className='font-description'
                        dangerouslySetInnerHTML={{
                            __html: getLocalizationConfig(font.localizations)?.mainText
                        }}
                    />
                    {getColImages('left')}
                </div>
                <div className='right'>{getColImages('right')}</div>
            </div>
            <div className='detail-columns'>
                <div className='left price-col'>
                    <div>
                        <img src={getLogoImage(font.code, font.hasLogoImgConfigured)} />
                    </div>
                    <div className='price-details'>
                        <div className='name'>{font.name}</div>
                        <div>
                            {t(`${FONTS_NS}.weight_label`)}:{' '}
                            {getFontTagNames(font.weightIDs, allTags).join(' / ')}
                        </div>
                        <div>
                            {t(`${FONTS_NS}.languages_label`)}:{' '}
                            {getFontTagNames(font.supportedLanguageIDs, allTags).join(
                                ' / '
                            )}
                        </div>
                        <div className='price'>
                            {t(`${FONTS_NS}.price_label`)}:{' '}
                            <div className='final-price'>
                                {getFontPrice()} {t(`${FONTS_NS}.euro`)}
                            </div>
                        </div>
                        <div className='to-cart'>
                            {t(`${FONTS_NS}.add_to_cart`)}{' '}
                            <FontAwesome
                                name='cart-plus'
                                className='cart'
                                onClick={handleAddToCart}
                            />
                        </div>
                    </div>
                </div>
                <div className='right downloads'>
                    <div className='title'>Downloads</div>
                    <div className='download-files'>
                        {t(`${FONTS_NS}.download`)}{' '}
                        <a
                            href={`${AMAZON_BASE_URL}/fonts/${font.code}/downloads/${font.code}_character_map.pdf`}
                            className='link'
                            target='_blank'
                            rel='noreferrer'>
                            {t(`${FONTS_NS}.here`)}
                        </a>{' '}
                        {t(`${FONTS_NS}.download_text`)}
                    </div>
                    <div className='download-files'>
                        {t(`${FONTS_NS}.download`)}{' '}
                        <a
                            href={`${AMAZON_BASE_URL}/downloads/Anagrafi_catalog_full.pdf`}
                            className='link'
                            target='_blank'
                            rel='noreferrer'>
                            {t(`${FONTS_NS}.here`)}
                        </a>{' '}
                        {t(`${FONTS_NS}.download_all_text`)}
                    </div>
                </div>
            </div>
        </ScrollableContainer>
    );
};

export default withRouter(withMenu(FontDetails));
