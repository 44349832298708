export const BASE_URL = 'https://personalizedgenericapi.azurewebsites.net/api/Anagrafi';
export const AMAZON_BASE_URL = 'https://d8lc6s7bpjqw2.cloudfront.net/anagrafi';

export const MENU_NS = 'menu';
export const CART_NS = 'cart';
export const FONTS_NS = 'fonts';
export const ABOUT_NS = 'about';
export const PRICES_NS = 'prices';

export const LOCAL_STORAGE_CART_KEY = 'anagrafi.cart';
