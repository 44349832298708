import i18n from './i18n';
import { AppStore } from './types';

export const getLocalizationConfig = (
    localizations: AppStore['fonts'][number]['localizations']
) => localizations.find(({ lang }) => lang === i18n.language);

export const getFontTagNames = (
    ids: number[],
    allTags: AppStore['filters'][number]['options']
): string[] =>
    ids.map(id => {
        const tag = allTags.find(tg => tg.id === id);

        switch (i18n.language) {
            case 'el':
                return tag?.nameGr;
            case 'en':
                return tag?.nameEn;
            default:
                return tag?.nameGr || '';
        }
    });

export const getFontTagCodes = (
    ids: number[],
    allTags: AppStore['filters'][number]['options']
): string[] =>
    ids.map(id => {
        const tag = (allTags || []).find(tg => tg.id === id);

        switch (i18n.language) {
            case 'el':
                return tag?.codeGr;
            case 'en':
                return tag?.codeEn;
            default:
                return tag?.codeGr || '';
        }
    });

export const isFontInCart = (cart: AppStore['cart'], fontId: number): boolean =>
    !!cart.find(({ id }) => id === fontId);

export const getCartTotal = (cart: AppStore['cart']): number =>
    cart.reduce((acc, { price }) => acc + price, 0);
