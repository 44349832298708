import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AMAZON_BASE_URL } from './Constants';
import Languages from './Languages';

const Home: React.FC<RouteComponentProps> = props => (
    <div className='home'>
        <div className='wrapper'>
            <div>
                <img
                    src={`${AMAZON_BASE_URL}/v2/images/general/logo_transparent.png`}
                />
            </div>
            <div className='synthesis'>
                <img src={`${AMAZON_BASE_URL}/v2/images/index/synthesis.png`} />
            </div>
            <div className='synthesis-footer'>
                <img src={`${AMAZON_BASE_URL}/v2/images/index/synthesis_footer.png`} />
            </div>
            <Languages {...props} redirect />
        </div>
    </div>
);

export default Home;
