import { Action, ActionType } from '../actions/actionTypes';
import { LOCAL_STORAGE_CART_KEY } from '../Constants';
import STORE from '../store';
import { AppStore } from '../types';

const reducer = (state = STORE.app, action: Action): AppStore => {
    switch (action.type) {
        case ActionType.REMOVE_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.filter(
                    ({ key }) => key !== action.payload
                )
            };
        case ActionType.ENQUEUE_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.concat(action.payload)
            };
        case ActionType.SET_FONTS:
            return { ...state, fonts: action.payload };
        case ActionType.SET_FILTERS:
            return { ...state, filters: action.payload };
        case ActionType.UPDATE_FILTER_OPTION:
            return {
                ...state,
                filters: state.filters.map(f => ({
                    ...f,
                    options:
                        f.id === action.payload.categoryId
                            ? f.options.map(o => ({
                                  ...o,
                                  checked:
                                      o.id === action.payload.id
                                          ? !o.checked
                                          : o.checked
                              }))
                            : f.options
                }))
            };
        case ActionType.UPDATE_CART:
            localStorage.setItem(
                LOCAL_STORAGE_CART_KEY,
                JSON.stringify(action.payload)
            );
            return { ...state, cart: action.payload };
        default:
            return state;
    }
};

export default reducer;
