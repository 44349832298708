import clsx from 'clsx';
import i18next from 'i18next';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Language } from './types';

const Languages: React.FC<
    RouteComponentProps & { showSelection?: boolean; redirect?: boolean }
> = ({ showSelection = false, redirect = false, history }) => {
    const getClassName = React.useCallback(
        (lang: Language) =>
            clsx('language', {
                selected: showSelection && lang === i18next.language
            }),
        [showSelection]
    );

    const handleLanguageChange = React.useCallback(
        (lang: Language) => () => {
            i18next.changeLanguage(lang);

            if (redirect) {
                history.push('/fonts');
            }
        },
        [history, redirect]
    );

    return (
        <div className='languages'>
            <div
                className={getClassName(Language.EL)}
                onClick={handleLanguageChange(Language.EL)}>
                ΕΛΛΗΝΙΚΑ
                <div className='flag greek' />
            </div>
            <div
                className={getClassName(Language.EN)}
                onClick={handleLanguageChange(Language.EN)}>
                ENGLISH
                <div className='flag english' />
            </div>
        </div>
    );
};

export default Languages;
